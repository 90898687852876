<template>
  <div v-show="isActive" class="my-4"><slot></slot></div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Tab",
  props: {
    name: { required: true },
    selected: { default: false }
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    href() {
      return "#" + this.name.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.isActive = this.selected;
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
