<template>
  <div class="container mx-auto">
    
    <tabs :handleTab="updateCurrentTab">
      <tab name="Facts" :selected="true" >
        <!-- <h1>Facts</h1> -->
        <div class="my-8 mx-auto">
          
          <p class="text-left font-bold">Fact JSON</p>
          <file-picker
            v-if="currentTab === 'Facts'"
            id="filePicker1"
            :allow-multiple="false"
            @vfp-file-added="fileAdded"
          />
          
          <div class="flex justify-end mt-2">
            <input type="text" placeholder="Enter file name" v-model="fileName" class="bg-gray-50 border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/3 p-2.5 border-green-900 mr-2" v-on:change="handleFileName"/>
            <button v-on:click="exportData"   class="btn btn-blue ">Export File</button>
          </div>

          <p v-if="errors.length" :class="errors.length ? 'text-red-900 mt-3' : 'mt-3'">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="(error, i) in errors" v-bind:key="i">{{ error }}</li>
            </ul>
          </p>
          <br />
          <json-editor
            :data-input="dataInput"
            :showCopyIcon="factShowCopyIcon"
            @data-output="(data) => (dataOutput = data)"
          >
          </json-editor>
        
        </div>
        
        
      </tab>

      <tab name="Grule" >
        <!-- <h1>Grule</h1> -->

        <div class="my-8 mx-auto">
          
            <p class="text-left font-bold">Grule JSON</p>
            <file-picker
              id="filePicker2"
              v-if="currentTab === 'Grule'"
              :allow-multiple="false"
              @vfp-file-added="fileAdded2"
            />
            
            <div class="flex justify-end mt-2" >
              <input type="text" v-model="fileName2" placeholder="Enter file name" class="bg-gray-50 border text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/3 p-2.5 border-green-900 mr-2" />
              <button v-on:click="exportData2" class="btn btn-blue ">Export Grule Json File</button>
            </div>
        </div>
        <p v-if="errors2.length" :class="errors2.length ? 'text-red-900' : ''">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="(error, i) in errors2" v-bind:key="i">{{ error }}</li>
          </ul>
        </p>
        
        <div class="flex">
          <div class="w-1/2 pr-3">
            <div class="text-left">
              <label for="gruleName" class="block mb-2 text-md  font-bold text-gray-900 dark:text-gray-300">Name *</label>
              <input v-model="gruleName" type='text' class="bg-gray-50 border text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-green-900" placeholder="Enter grule name."/>
            </div>

            <div class="text-left mt-2">
              <label for="gruleDescription" class="block mb-2 text-md font-bold text-gray-900 dark:text-gray-300">Description</label>
              <input v-model="gruleDescription" type="text" class="bg-gray-50 border text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 border-green-900" placeholder="Enter grule description."/>
            </div>

            <div class="text-left mt-2">
              <label for="gruleDescription" class="block mb-2 text-md font-bold text-gray-900 dark:text-gray-300">Salience</label>
              <input v-model="salience" type="number" class="bg-gray-50 border text-gray-900 text-md rounded focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 border-green-900" placeholder=""/>
            </div>
          </div>
          <div class="w-1/2 p-1">
            <div class="border border-green-900 rounded h-full text-left pt-3">
              <div class="p-2 ">
                <p class="block mb-2 text-md font-medium text-gray-900 dark:text-gray-300">
                  <strong>Grule</strong> is a Rule Engine library for the Go (Golang) programming language. Inspired by the acclaimed JBOSS Drools, and done in a much simpler manner.
                  <br /><br />
                  Like <strong>Drools</strong>, <strong>Grule</strong> has its own <i>DSL</i> or Domain-Specific Language.
                  <br /><br />
                  Grule rules can be represented in JSON form and translated for use by the rule engine into standard Grule syntax. The design of the JSON format is intended to offer a high level of flexibility to suit the needs of the user.
                  <br /><br />
                  The basic structure of a JSON rule is as follows:<br /><br />
                
                <pre lang="drool" class="text-left text-xs italic font-normal text-gray-500 bg-gray-50 border border-gray-200 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                <code>
  {
      "name": "SpeedUp",
      "desc": "When testcar is speeding up we keep increase the speed.",
      "salience": 10,
      "when": ...,
      "then": [
          ...
      ]
  }
                </code>
              </pre>
              <br />
                  <strong>Basic Representation</strong>
                </p>
                <p>The most basic representation of this rule in JSON is as follows:</p>
                <pre lang="drool" class="text-left p-1 text-xs italic font-normal text-gray-500 bg-gray-50 border border-gray-200 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                <code>
{
  "name": "SpeedUp",
  "desc": "When testcar is speeding up we increase the speed.",
  "salience": 10,
  "when": "TestCar.SpeedUp == true && TestCar.MaxSpeed > TestCar.Speed",
  "then": [
    "TestCar.Speed = TestCar.Speed + TestCar.SpeedIncrement",
    "DistanceRecord.TotalDistance = DistanceRecord.TotalDistance + TestCar.Speed"
  ]
}
                </code>
              </pre>
                      
              </div>
            </div>
          </div>

        </div>
        

        <p class="text-left font-bold">Condition</p>
        <json-editor
          :data-input="dataCondition"
          @data-output="(data) => (dataOutputCondition = data)"
        >
        </json-editor>
      
        <hr class="mt-8 mb-4"/>

        <p class="text-left font-bold">Decision</p>
        <json-editor
          :data-input="dataDecision"
          @data-output="(data) => (dataOutputDecision = data)"
        >
        </json-editor>
          
      
      </tab>
    </tabs>

    
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import JsonEditor from "../components/JsonEditor/components/json-editor.vue";
import FilePicker from "../components/FilePicker.vue";
import Tabs from "../components/Tabs.vue";
import Tab from "../components/Tab.vue";
// import _ from 'lodash';
// const { flatten } = require('safe-flat')


export default {
  // eslint-disable-next-line
  name: "Home",
  components: {
    JsonEditor,
    FilePicker,
    Tabs,
    Tab
  },
  data() {
    return {
      currentTab: 'Facts',
      dataInput: {},
      dataOutput: {},
      fileUploaded: [],
      fileName: '',
      errors: [],
      file_path: '',
      factShowCopyIcon: true,
      //
      dataInput2: {},
      gruleName: '',
      gruleDescription: '',
      salience: 0,
      dataCondition:{},
      dataDecision:{},
      dataOutputCondition:{},
      dataOutputDecision:{},
      dataOutput2: {},
      fileUploaded2: [],
      fileName2: '',
      errors2: [],
      file2_path: '',
    };
  },
  methods: {
    updateCurrentTab(tab) {
      this.currentTab = tab
    },
    exportData () {
      console.log('output --- ', this.dataOutput, typeof this.dataOutput);
       this.errors = [];

      if (!this.fileName) {
        this.errors.push("File Name is required.");
        return;
      }
      const data = JSON.stringify(this.dataOutput);
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
      if(this.fileName === ''){
        a.download = "data.json";
      } else {
        a.download = this.fileName + '.json';
      }
      
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    exportData2 () {
      console.log('output --- ', this.dataInput2, typeof this.dataInput2);
       this.errors2 = [];

      if (!this.gruleName) {
        this.errors2.push("Grule Name is required.");
        return;
      }

      if (!this.fileName2) {
        this.errors2.push("File Name is required.");
        return;
      }

      this.dataInput2.name = this.gruleName;
      this.dataInput2.desc = this.gruleDescription;
      this.dataInput2.salience = this.salience;
      this.dataInput2.when = this.dataOutputCondition;
      this.dataInput2.then = this.dataOutputDecision;
      
      const data = JSON.stringify(this.dataInput2);
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
      if(this.fileName2 === ''){
        a.download = "data.json";
      } else {
        a.download = this.fileName2 + '.json';
      }
      
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
     onFileChange (file) {
      // Handle files like:
      this.fileUploaded = file
      console.log('fileupload', file);
    },
    handleFileName () {
    },
    fileAdded (files) {
      this.file_path = files[0]
      const fileData = new FormData()
      fileData.append('file_path ', this.file_path)
      // this.readJsonFile(this.file_path)
      const reader = new FileReader()
      reader.onload = e => {
        const json = JSON.parse(e.target.result)
        this.dataInput = json
      }
      reader.readAsText(this.file_path)
    },
    fileAdded2 (files) {
      console.log('vfp2:', files)
      this.file2_path = files[0]
      const fileData = new FormData()
      fileData.append('file2_path ', this.file2_path)
      // this.readJsonFile(this.file_path)
      const reader = new FileReader()
      reader.onload = e => {
      
        const json = JSON.parse(e.target.result)
        this.dataInput2 = json
        this.gruleName = json.name;
        this.gruleDescription = json.desc;
        this.salience = json?.salience;
        this.dataCondition = json?.when;
        this.dataDecision = json?.then;
        // console.log('this.sampleData1', this.sampleData1)
      }
      reader.readAsText(this.file2_path)
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    }
  },
  computed: {
    isExpertAble() {
      console.log('wather---', this.dataInput, this.isEmptyObject(this.dataInput))
      return this.isEmptyObject(this.dataInput);
    },
    isExpertAble2() {
      console.log('wather---', this.dataInput2, this.isEmptyObject(this.dataInput2))
      return this.isEmptyObject(this.dataInput2);
    }, 
    isCurrentTab() {
      return this.currentTab;
    }
  },
  watch: {
    fileName: function(val, oldVal) {
      this.value = val;
      this.oldValue = oldVal;
    },
    fileName2: function(val, oldVal) {
      this.value = val;
      this.oldValue = oldVal;
    },
    currentTab: function(val, oldVal) {
      this.value = val;
      this.oldValue = oldVal;
    }
  }
};
</script>
<style lang="css">
</style>