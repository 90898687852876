import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Notifications from "vue3-vt-notifications";

createApp(App)
  .use(Notifications)
  .use(router)
  .use(store)
  .mount('#app')
