<template>
   <div>
        <div class="tabs flex">
            <ul class="flex justify-start ">
            <li v-for="(tab, index) in tabs" :class="{ 'is-active': tab.isActive }" v-bind:key="index">
                <a :href="tab.href" @click="selectTab(tab)" class="p-3 py-4 border-b font-bold">{{ tab.name }}</a>
            </li>
            </ul>
        </div>

        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Tabs',
  props: {
    handleTab: {
      type: Function
    }
  },
  data() {
      return {tabs: [] };
  },

  created() {
      this.tabs = this.$children;
  },
  methods: {
      selectTab(selectedTab) {
        this.handleTab(selectedTab.name)
          this.tabs.forEach(tab => {
              tab.isActive = (tab.name == selectedTab.name);
          });
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-active a{
  border-bottom-color: #00d1b2;
  color: #00d1b2;
}
</style>
