<template>
    <div
      id="nav"
      v-show="$route.path !== '/login'"
      class="absolute bg-white h-16 z-10 w-full flex justify-between"
    >
      <div
        class="
          max-w-none
          md:max-w-xs
          w-full
          bg-sky401
          flex
          justify-center
          items-center
          text-white text-2xl
        "
      >
        wiserentr
      </div>
      <div class="hidden md:flex justify-end items-center">
        <p class="text-sky401 font-bold mr-3">Carlos Sanchez</p>
        <p class="px-4 mr-3">Última sesión: 16/02/2021</p>
        <p class="text-xl px-4 mr-3 cursor-pointer" @click="Logout">Logout</p>
      </div>
      <!-- Mobile menu button -->
      <div class="md:hidden flex items-center bg-sky401">
        <button
          class="outline-none mobile-menu-button mr-4"
          @click="handleMobileMenu"
        >
          <svg
            class="w-6 h-6 text-white hover:text-black"
            x-show="!showMenu"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
       
      </div>
       <!-- mobile menu -->
        <div class="hidden mobile-menu">
          <ul class="">
            <li>
              <a
                href="/login"
                class="
                  block
                  text-sm
                  px-2
                  py-4
                  hover:bg-green-500
                  transition
                  duration-300
                "
                >Logout</a
              >
            </li>
          </ul>
        </div>
      <!-- <router-link to="/">Facts</router-link>  -->
      <!-- |
      <router-link to="/grule">Grule</router-link> -->
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    Logout() {
      this.$router.push({ path: "/login" });
    },
    handleMobileMenu() {
      const btn = document.querySelector("button.mobile-menu-button");
      const menu = document.querySelector(".mobile-menu");

      btn.addEventListener("click", () => {
        menu.classList.toggle("hidden");
      });
    },
  },
  computed: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  /* padding: 30px; */
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.json-editor .btn-icon {
  --btn-icon-color: var(--color-secondary);
  --btn-icon-border-color: var(--color-secondary);
  width: calc(var(--font-size) * 1.15);
  display: inline-block;
  border: 1px solid var(--btn-icon-border-color);
  border-radius: var(--indent-secondary);
  transition: color 0.2s ease, border-color 0.2s ease, transform 0.2s ease;
}
.json-editor .btn-icon:hover {
  color: var(--btn-icon-color);
  border-color: var(--btn-icon-border-color);
}
.json-editor .btn-icon_add:hover {
  --btn-icon-color: var(--color-accent);
  --btn-icon-border-color: var(--btn-icon-color);
}
.json-editor .btn-icon_delete:hover {
  --btn-icon-color: var(--color-danger);
  --btn-icon-border-color: var(--btn-icon-color);
}
.json-editor .btn-icon_drag {
  cursor: move;
}
.json-editor .btn-icon_collapse {
  --btn-icon-border-color: transparent;
  transform: rotateZ(180deg);
}
.json-editor [aria-expanded="false"] .btn-icon_collapse {
  transform: rotateZ(90deg);
}
.json-editor {
  --color-primary: black;
  --color-secondary: grey;
  --color-accent: green;
  --color-danger: red;
  --color-input-bg: white;
  --font-size: 14px;
  --label-font-size: 16px;
  --indent-primary: 4px;
  --indent-secondary: 3px;
  --indent-right: 12px;
}
.json-editor .json-editor__input,
.json-editor .json-editor__select,
.json-editor .json-editor__btn {
  line-height: 1;
  background-color: var(--color-input-bg);
  box-shadow: none;
  outline: none;
}
.json-editor .json-editor__input,
.json-editor .json-editor__select {
  display: block;
  min-width: 0;
  padding: var(--indent-secondary) calc(var(--indent-secondary) * 2);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--indent-secondary);
  font-size: var(--font-size);
}
.json-editor .json-editor__input:focus,
.json-editor .json-editor__select:focus {
  border-color: var(--color-accent);
}
.json-editor .json-editor__select,
.json-editor .json-editor__btn {
  cursor: pointer;
}
.json-editor .json-editor__input {
  flex-grow: 1;
  border-color: transparent;
  text-overflow: ellipsis;
  appearance: textfield;
}
.json-editor .json-editor__input::-webkit-outer-spin-button,
.json-editor .json-editor__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.json-editor .json-editor__select {
  flex-shrink: 0;
  margin-left: auto;
  border-color: var(--color-secondary);
}
.json-editor .json-editor__btn_icon {
  padding: 0;
  font-size: var(--label-font-size);
  text-align: center;
  border: none;
  border-radius: 0;
}
.json-editor .object-view {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: var(--indent-primary);
  margin-bottom: var(--indent-primary);
  border-bottom: 1px dashed transparent;
}
.json-editor .object-view--collapsed {
  /* border-color: var(--color-secondary); */
}
.json-editor .object-view_list {
  padding-right: var(--indent-right);
  border-right: 1px dashed var(--color-secondary);
  border-radius: var(--indent-primary);
}
.json-editor .object-view_list > .object-view__key {
  flex-grow: 1;
  display: flex;
}
.json-editor .object-view_list > .object-view__key > *:not(:last-child) {
  /* margin-right: calc(var(--indent-right) * 2); */
  margin-right: calc(var(--indent-right));
}
.json-editor .object-view_list > .object-view__key .key-input {
  flex-grow: 1;
}
.json-editor .object-view_list > .object-view__value {
  flex-shrink: 0;
  order: 1;
  width: 100%;
  margin-top: calc(var(--indent-primary) * 2);
}
.json-editor .object-view > *:not(:last-child) {
  /* padding-right: calc(var(--indent-primary) * 2); */
}
.json-editor .object-view__container {
  margin-left: calc(var(--indent-primary) * 2);
  text-align: left;
}
.json-editor .object-view__key {
  flex-shrink: 0;
  display: flex;
}
.json-editor .object-view__value {
  flex-grow: 1;
}
.json-editor .object-view__tools {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: var(--indent-right);
}
.json-editor .object-view__tools > *:not(:last-child) {
  margin-right: calc(var(--indent-primary) * 2);
}
.json-editor .object-view .key__input {
  font-weight: bold;
  font-size: 1em;
  border-bottom: dotted 1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.json-editor .object-view .key__input:focus {
  border-color: var(--color-accent);
  border-bottom: solid 1px var(--color-accent);
}

.json-editor .object-view .value__input {
  width: 100%;
  border-color: transparent;
  color: var(--color-accent);
  border-bottom: dotted 1px black;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: var(--label-font-size);
}
.json-editor .object-view .value__input:focus {
  border-color: var(--color-accent);
  border-bottom: solid 1px;
}
.json-editor .new-item-form {
  display: block;
}
.json-editor .new-item-form > *:not(:last-child) {
  margin-bottom: calc(var(--indent-primary) * 2);
}
.json-editor .new-item-form__group {
  display: flex;
  align-items: center;
}
.json-editor .new-item-form__group > *:not(:last-child) {
  margin-right: calc(var(--indent-primary) * 2);
}
.json-editor .new-item-form__group_btns {
  justify-content: flex-end;
}
.json-editor .new-item-form__input {
  flex-grow: 1;
  border-color: var(--color-secondary);
}
.json-editor .new-item-form__btn {
  padding: var(--indent-secondary) calc(var(--indent-secondary) * 2);
  border: 1px solid var(--color-primary);
  border-radius: var(--indent-secondary);
  font-size: calc(var(--font-size));
  transition: color 0.2s ease, border-color 0.2s ease;
}
.json-editor .new-item-form__btn:hover {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
* {
  box-sizing: border-box;
}
.page-title {
  margin-top: 32px;
  text-align: center;
}
.page-text {
  padding: 30px;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
}
.container {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 20px;
  align-items: flex-start;
  padding: 30px;
}
.json-output {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
}

.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.btn-disable {
  @apply bg-gray-500 text-white;
}

span.json-editor__input.key__input {
  border-bottom: dotted 0px !important;
}
</style>
