<template>
  <div class="object-view__container"> 
    <draggable
      v-model="currentData"
      class="object-view__drag"
      handle="[data-dragbar]"
      @end="dragEnd"
    >
      <div
        v-for="(item, i) in currentData"
        :key="`${item.type}-${i}`"
        :data-key="`${item.type}-${i}`"
        class="object-view"
        :class="{
          'object-view_list': item.type === 'object' || item.type === 'array',
          'object-view--collapsed': item.collapsed,
        }"
      >
        <div class="object-view__key">
          <button
            v-if="item.type === 'object' || item.type === 'array'"
            type="button"
            class="json-editor__btn json-editor__btn_icon"
            aria-controls="trigger"
            :aria-expanded="!item.collapsed ? 'true' : 'false'"
            @click="item.collapsed = !item.collapsed"
          >
            <b>
              <slot name="icon-collapse"
                ><span
                  class="btn-icon btn-icon_collapse"
                  :title="item.collapsed ? 'Expand' : 'Collapse'"
                  >^</span
                ></slot
              >
              <i v-if="item.type === 'object'">{{ `{ ${item.value.length} }` }}</i>
              <i v-if="item.type === 'array'">{{ `[ ${item.value.length} ]` }}</i>
            </b>
          </button>
          <span title="Click to copy">
            
            <DuplicateIcon 
              v-if="item.key !== null && showCopyIcon === true"
              class="cursor-pointer" 
              @click="copied(item.serialKey)"
            />
           
          </span>
          <input
            v-if="item.key !== null"
            v-model.trim="item.key"
            type="text"
            :placeholder="item.placeholder"
            :data-key="item.serialKey"
            class="json-editor__input key__input"
            @blur="checkItemKey(item, $event)"
          />
          <i v-else>{{ i }}. </i>
        </div>
        
        <div v-show="!item.collapsed" class="object-view__value">
          <item-view
            v-if="item.type === 'object' || item.type === 'array'"
            v-model="item.value"
            :item-key="item.serialKey"
            :item-type="item.type"
            :parsed-data="item.value"
             :showCopyIcon="showCopyIcon"
          >
            <!-- eslint-disable-next-line -->
            <template  #icon-add v-bind:parentKey="item.key">
              <slot name="icon-add" v-bind:icon-add="item"></slot> 
            </template>
            <template #icon-delete>
              <slot name="icon-delete"> </slot>
            </template>
            <template #icon-drag>
              <slot name="icon-drag"> </slot>
            </template>
            <template #icon-collapse>
              <slot name="icon-collapse"> </slot>
            </template>
          </item-view>
          <template v-else>
            <span v-if="item.type === 'null'" class="json-editor__input value__input">null</span>
            
            <input
              v-if="item.type === 'string'"
              v-model.trim="item.value"
              type="text"
              class="json-editor__input value__input"
            />
            <input
              v-if="item.type === 'number'"
              v-model.number="item.value"
              type="number"
              class="json-editor__input value__input"
              step="0.1e-100"
            />
            <select
              v-if="item.type === 'boolean'"
              v-model="item.value"
              class="json-editor__select value__input"
            >
              <option :value="true">true</option>
              <option :value="false">false</option>
            </select>
          </template>
        </div>
        <div class="object-view__tools">
          <select v-model="item.type" class="json-editor__select" @change="item.changeType()">
            <option v-for="(type, j) in typesList" :key="j" :value="type">
              {{ type }}
            </option>
          </select>
          <button type="button" class="json-editor__btn json-editor__btn_icon" data-dragbar>
            <slot name="icon-drag">
              <span class="btn-icon btn-icon_drag" title="Move">=</span>
            </slot>
          </button>
          <button
            type="button"
            class="json-editor__btn json-editor__btn_icon"
            @click="deleteItem(i)"
          >
            <slot name="icon-delete">
              <span class="btn-icon btn-icon_delete" title="Delete">-</span>
            </slot>
          </button>
        </div>
        
      </div>
    </draggable>

    <item-form
      v-if="itemForm"
      :required-key="itemType !== 'array'"
      :parent-key="itemKey"
      @add-new-item="createItem"
      @cancel-new-item="toggleForm"
    ></item-form>

    <button
      v-if="!itemForm"
      type="button"
      class="json-editor__btn json-editor__btn_icon"
      @click="toggleForm"
      :data-parent-key="`${itemKey}`"
    >
      <slot name="icon-add" >
        <span class="btn-icon btn-icon_add" title="Add" >+</span>
      </slot>
    </button>

    <!-- Copy notification -->
    <notificationGroup group="top">
      <div class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end">
        <div class="max-w-sm w-full">
          <notification v-slot="{notifications}">
            <div
              class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex justify-center items-center w-12 bg-green-500">
                <svg
                  class="h-6 w-6 fill-current text-white"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  ></path>
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-green-500 font-semibold">{{notification.title}}</span>
                  <p class="text-gray-600 text-sm">{{notification.text}}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ItemForm from './item-form.vue';
import { DuplicateIcon } from '@vue-hero-icons/solid'
import { createPopper } from "@popperjs/core";

export default {
  name: 'ItemView',
  components: {
    draggable,
    ItemForm,
    DuplicateIcon
  },
  inject: ['typesList'],
  props: {
    itemType: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      required: false
    },
    parsedData: {
      type: Array,
      required: true,
    },
    parsedItem: {
      type: Object,
      required: false
    },
    showCopyIcon: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  data() {
    return {
      currentData: this.parsedData ?? [],
      itemForm: false,
      tooltipShow: false
    };
  },
  watch: {
    parsedData: {
      handler() {
        this.currentData = this.parsedData;
      },
    },
    itemForm (val, oldVal) {
      console.log(oldVal)

      this.itemForm = val;
    }
  },
  methods: {
    deleteItem(i) {
      this.currentData.splice(i, 1);
      this.$emit('input', this.currentData);
    },
    toggleTooltip: function(){
      if(this.tooltipShow){
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "left"
        });
      }
    },
    createItem(item) {
      // console.log('add item', item, this.currentData)
      this.currentData.push(item);
      this.$emit('input', this.currentData);
      this.toggleForm();
    },
    toggleForm() {
      // console.log('toggleFrom', this.itemKey)
      this.itemForm = !this.itemForm;
    },
    dragEnd() {
      this.$emit('input', this.currentData);
    },
    checkItemKey(item, e) {
      if (!item.checkKey()) e.target.focus();
    },
    async copied(key) {
      // console.log('copied key', key);
      try {
        await navigator.clipboard.writeText(key);
        this.onClickTop()
        // alert('Copied');
      } catch($e) {
        alert('Cannot copy');
      }
    },
    onClickTop() {
      this.$notify(
        {
          group: "top",
          title: "Success",
          text: "Copied!"
        },
        4000
      );
    },
  },
  
};
</script>
